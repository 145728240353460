import { type SagaReturnType, put, cancel, takeLatest } from 'redux-saga/effects';

import * as api from 'services/api';
import type { PaginationInput, UserType } from 'app/entities';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: actions.load.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.load>) {
  const { login } = action.payload;

  const user = yield* select(userStore.selectors.getMy);

  const pagination: PaginationInput = { pageSize: 50 };

  const [subscribers, subscriptions] = yield* call(() =>
    Promise.all([api.resource.user.relation.subscribers(login, pagination), api.resource.user.relation.subscriptions(login, pagination)]),
  );

  if (subscribers.error || !subscribers.data || subscriptions.error || !subscriptions.data) {
    Alert.error(subscribers.error?.message || subscriptions.error?.message || 'Server error #30');
    yield put(actions.loadDone());
    yield cancel();
    return;
  }

  const loadedUsers = ([...subscribers.data.items, ...subscriptions.data.items] as UserType[]).map((item) => {
    if (!item || !user || item.id !== user.id) {
      return item;
    }
    return user;
  });

  yield put(
    userStore.actions.setProfile({
      data: loadedUsers,
    }),
  );
  yield put(actions.addPage('subscribers', subscribers.data.items as UserType[], subscribers.data.paginationInfo));
  yield put(actions.addPage('subscriptions', subscriptions.data.items, subscriptions.data.paginationInfo));

  yield put(actions.loadDone());
}
