import { memo, type SetStateAction } from 'react';
import { Box, RadioGroup, Radio, FormControl, Typography, List, ListItem, Divider } from '@mui/joy';
import Icon from 'ui/Icon';

import type { PromoDataType } from 'widgets/Subscription/ui/PromoInput';
import usePlan from 'widgets/Subscription/model/usePlan';

import useTrialPlans from '../../../model/useTrialPlans';
import useCosts from '../../../model/useCosts';

type BillingFormProps = {
  planId: string;
  onPlanChange: (event: { target: { value: SetStateAction<string> } }) => void;
  isDisabled?: boolean;
  isSkipLoading?: boolean;
  promo?: PromoDataType | null;
};

const BillingForm = (props: BillingFormProps) => {
  const { planId, onPlanChange, isDisabled, isSkipLoading, promo } = props;

  const trialPlans = useTrialPlans();
  const { plan, period } = usePlan(planId);
  const costMap = useCosts({ promo });

  const currentPeriodId = period?.id || 'monthly';
  const currentCost = costMap[planId];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: 600,
        gap: 2,
      }}
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <Icon
          family="kit"
          name="ikicopilot"
          fw
          size="xl"
          color="icon"
          sx={{
            width: 25,
            height: 22,
            ml: -0.4,
          }}
        />
        <Typography fontSize={16} fontWeight={500}>
          IKI {plan?.name} {!!plan?.note && plan?.note}
        </Typography>
      </Box>
      <FormControl disabled={isDisabled || isSkipLoading}>
        <RadioGroup value={planId} onChange={onPlanChange}>
          <List>
            {trialPlans.map((item) => {
              const cost = costMap[item.identity.planId];
              return (
                <ListItem key={item.identity.planId} sx={{ px: 0, alignItems: 'stretch' }}>
                  <Radio value={item.identity.planId} label={`${item.identity.label}`} />
                  <Box flex={1} />
                  {cost.type === 'regular' && <Typography component="span">{`${cost.monthly.formatted.value} / mo`}</Typography>}
                  {cost.type === 'special' && (
                    <Box textAlign="right">
                      <Typography component="span">
                        {`${cost.monthly.formatted.discountedValue} / mo for first ${promo?.durationInMonths} months`}
                      </Typography>
                      <Typography component="span" color="neutral" level="body-xs">
                        {`${cost.monthly.formatted.value} / mo thereafter`}
                      </Typography>
                    </Box>
                  )}
                </ListItem>
              );
            })}
          </List>
        </RadioGroup>
      </FormControl>
      <Divider sx={{ mt: -2, opacity: 0.5 }} />
      <Box display="flex" flexDirection="column" gap={0.25} mb={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography>Total after trial</Typography>
          {currentCost.type === 'regular' && !promo && (
            <Typography fontWeight={400}>
              {currentCost[currentPeriodId].formatted.total} billed {currentPeriodId}
            </Typography>
          )}
          {currentCost.type === 'special' && promo && (
            <Box textAlign="right">
              <Typography fontWeight={400}>
                <Typography color="primary">{currentCost[currentPeriodId].formatted.discountedTotal}</Typography> billed{' '}
                {currentPeriodId === 'monthly' ? `for first ${promo?.durationInMonths} months` : 'for the first year'}
              </Typography>
              <Typography color="neutral" level="body-xs">
                {currentCost[currentPeriodId].formatted.total} annually thereafter
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight={500}>Total due today</Typography>
          <Typography fontWeight={500}>$0.00</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(BillingForm);
