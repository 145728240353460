import { useCallback } from 'react';
import network from 'lib/network';

interface TagResponse {
  id: number;
  directionId: number;
  rank: number;
  text: string;
}

export const useFetchTags = () => {
  return useCallback(async (input: string): Promise<string[]> => {
    if (!input) {
      return [];
    }

    const { data, hasError, errors } = await network
      .request<TagResponse[]>(`/stack-1/skills/search?pageNum=0&pageSize=50&q=${encodeURIComponent(input)}`)
      .get();

    if (hasError) {
      console.error('Failed to fetch tags:', errors?.[0].message);
      return [];
    }
    return data?.map((item) => item.text) || [];
  }, []);
};
