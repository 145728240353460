import { createAction } from '@reduxjs/toolkit';

import type { UserInput } from 'app/entities';

import { prepareActionDone } from 'store/utils/saga/actions';

export const updateData = createAction('PROFILE/UPDATE_DATA', (data: Partial<UserInput>, successNavigate?: string) => ({
  payload: {
    data,
    successNavigate,
  },
}));

export const autoSave = createAction('PROFILE/AUTO_SAVE', (data: Partial<UserInput>) => ({
  payload: {
    data,
  },
}));

export const updateDataDone = createAction('PROFILE/UPDATE_DATA_DONE', prepareActionDone);

export const deleteAccount = createAction('PROFILE/DELETE_ACCOUNT');
