import { memo, type FC, type ReactElement, type ReactNode } from 'react';
import { Box, Typography } from '@mui/joy';
import { isEqual } from 'lodash';

export type PlanHeadProps = {
  name: string;
  marker?: ReactElement | string;
  description: string;
  note: string;
  costValue: number;
  costBy: string;
  costNote: string;
  beforePrice?: ReactNode;
};

const PlanHead: FC<PlanHeadProps> = (props) => {
  const { name, marker, description, note, costValue, costBy, costNote, beforePrice } = props;

  return (
    <Box>
      <Box>
        <Box position="relative" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="baseline">
            <Typography component="h2" fontSize={34} fontWeight={400} lineHeight={1} textColor="text.primary">
              <Box component="span" dangerouslySetInnerHTML={{ __html: `${name}` }} />
            </Typography>
            {typeof marker === 'string' && (
              <Typography ml={0.75} fontSize={14} fontWeight={500} lineHeight={1}>
                {marker}
              </Typography>
            )}
            {typeof marker !== 'string' && marker}
          </Box>
          {note && (
            <Typography
              component="div"
              fontSize={12}
              textColor="var(--joy-palette-text-primary-invert)"
              bgcolor="var(--joy-palette-text-primary)"
              borderRadius={(theme) => theme.radius.sm}
              py={0.25}
              px={1.5}
            >
              <span dangerouslySetInnerHTML={{ __html: `${note}` }} />
            </Typography>
          )}
        </Box>
        <Typography component="div" textColor="text.primary" mt={0.5}>
          <span dangerouslySetInnerHTML={{ __html: `${description}` }} />
        </Typography>
      </Box>
      {beforePrice}
      <Box display="flex" flexDirection="row" alignItems="center" gap={1} mt={2}>
        <Typography fontSize={56} component="div" textColor="text.primary" lineHeight={1.2}>
          <span dangerouslySetInnerHTML={{ __html: `$${costValue}` }} />
        </Typography>
        <Typography fontSize={22} lineHeight={1.2} mb={0.5} component="div" textColor="text.tertiary">
          <span dangerouslySetInnerHTML={{ __html: `${costBy}` }} />
        </Typography>
      </Box>
      <Typography component="div" textColor="text.tertiary" sx={{ opacity: 0.8 }}>
        <span dangerouslySetInnerHTML={{ __html: `${costNote}` }} />
      </Typography>
    </Box>
  );
};

export default memo(PlanHead, (prevProps, nextProps) => isEqual(prevProps, nextProps));
