import { createImageUpload } from 'novel/plugins';
import { toast } from 'react-toastify';
import { uploadFilesToS3 } from 'utils';

const onUpload = (file: File) => {
  return toast.promise(
    uploadFilesToS3('resources', [file])
      .then((res) => {
        const [result] = res;
        return result.data?.url;
      })
      .catch((error) => {
        console.error('Image upload failed:', error);
        throw error;
      }),
    {
      pending: 'Uploading image...',
      success: 'Image uploaded successfully.',
      error: 'Error uploading image. Please try again.',
    },
  );
};

const validateFn = (file: File) => {
  if (!file.type.includes('image/')) {
    toast.error('File type not supported.');
    return false;
  }
  if (file.size / 1024 / 1024 > 20) {
    toast.error('File size too big (max 20MB).');
    return false;
  }
  return true;
};

export const uploadFn = createImageUpload({
  onUpload,
  validateFn,
});
