export function trimHtml(html: string): string {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  return Array.from(tempDiv.childNodes)
    .map((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        if (!element.textContent?.trim()) {
          return '';
        }
        return element.outerHTML.trim();
      }
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent?.trim() || '';
      }
      return '';
    })
    .filter(Boolean)
    .join('');
}
