import { useEditor } from 'novel';
import { forwardRef, memo, useImperativeHandle } from 'react';

export type ContentCommandMethods = {
  insert: (text: string) => void;
  focus: () => void;
};

const ContentCommand = forwardRef<ContentCommandMethods>((props, forwardedRef) => {
  const { editor } = useEditor();

  useImperativeHandle(forwardedRef, () => ({
    insert: (text: string) => {
      if (!editor) {
        return;
      }
      const { selection } = editor.view.state;
      editor
        .chain()
        .focus()
        .insertContentAt(
          {
            from: selection.from,
            to: selection.to,
          },
          text,
        )
        .run();
    },
    focus: () => {
      if (!editor) {
        return;
      }
      editor.chain().focus().run();
    },
  }));

  return null;
});

export default memo(ContentCommand);
