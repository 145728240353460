import { type SagaReturnType, takeEvery, cancel, put, delay } from 'redux-saga/effects';
import type { NoteType } from 'app/entities';
import { prepareHtml, requestCursorModifier } from 'utils';
import { call } from 'store/utils/saga/effects';
import network from 'lib/network';

import { actions } from '../slice';
import * as utils from '../utils';

export const config = {
  action: actions.loadById.type,
  method: takeEvery,
};

async function loadAllNotePages(initialUrl: string, pageSize = 10000): Promise<{ data: NoteType | null; errors: Error[] | null }> {
  const firstResponse = await network.request<NoteType>(`${initialUrl}?pageSize=${pageSize}`).get(requestCursorModifier());
  const { data, errors } = firstResponse;

  if (!data || (errors && errors.length > 0)) {
    return { data: null, errors: errors || [new Error('Error loading note')] };
  }

  const { items } = data;
  let { hasNext, nextUrl } = data;
  let fullText = items.text || '';

  function getCursorFromUrl(url: string): string | null {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.searchParams.get('cursor');
    } catch (e) {
      return null;
    }
  }

  while (hasNext && nextUrl) {
    const nextCursor = getCursorFromUrl(nextUrl);
    if (!nextCursor) {
      return { data: null, errors: [new Error('Could not extract cursor from nextUrl')] };
    }

    const nextPageUrl = `${initialUrl}?pageSize=${pageSize}&cursor=${encodeURIComponent(nextCursor)}`;
    const nextResponse = await network.request<NoteType>(nextPageUrl).get(requestCursorModifier());
    const { data: nextData, errors: nextErrors } = nextResponse;

    if (!nextData || (nextErrors && nextErrors.length > 0)) {
      return { data: null, errors: nextErrors || [new Error('Error loading note page')] };
    }

    fullText += nextData.items.text || '';

    hasNext = nextData.hasNext;
    nextUrl = nextData.nextUrl;
  }

  const preparedHtml = await prepareHtml({ text: fullText }, { async: true });
  const finalItem: NoteType = {
    ...items,
    isEditable: fullText.length <= 400000,
    text: preparedHtml || '',
  };

  return { data: finalItem, errors: null };
}

export function* func(action: SagaReturnType<typeof actions.loadById>) {
  const { id } = action.payload;

  if (!id) {
    yield put(actions.loadByIdDone({ id }));
    yield cancel();
    return;
  }

  const { data, errors } = yield* call(() => loadAllNotePages(`/stack-1/notes/${id}`, 5000000));

  if (!data || (errors?.length || 0) > 0) {
    yield put(actions.loadByIdDone({ id }));
    yield cancel();
    return;
  }

  const preparedNote = yield* utils.prepareNote(data);
  yield put(actions.setItem({ data: preparedNote }));
  yield delay(20);
  yield put(actions.loadByIdDone({ id }));
}
