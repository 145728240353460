import pluralize from 'pluralize';

function getTrialMessage(trial: { day?: number; month?: number }): string {
  if (typeof trial.day === 'number') {
    return `${trial.day} ${pluralize('day', trial.day)} free trial`;
  }
  if (typeof trial.month === 'number') {
    return `${trial.month} ${pluralize('month', trial.month)} free trial`;
  }
  return '';
}

export default getTrialMessage;
