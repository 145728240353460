import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Box, ButtonGroup, Card, type CardProps, CardActions, IconButton } from '@mui/joy';
import { type EditorInputMethodsType } from 'ui/EditorInput';

import { useEditorState } from 'hooks';

import Icon from 'ui/Icon';
import EditorInput from 'ui/EditorInput';
import { trimHtml } from 'widgets/Notes/model/trimHtml';
import CircularProgressCounter, { type CircularProgressCounterMethodsType } from 'widgets/Notes/ui/CircularProgressCounter';
import { useSelector } from 'react-redux';
import * as contentStore from 'store/nodes/content';
import { useRoute } from 'navigation/hooks';

type NewNoteProps = {
  onAdd?: (text: string) => void;
  isLoading?: boolean;
  disabled: boolean;
} & CardProps;

const NewNote = (props: NewNoteProps) => {
  const { onAdd, sx, ...rest } = props;
  const route = useRoute<'Content'>();
  const { resourceId } = route.params;

  const editorRef = useRef<EditorInputMethodsType | null>(null);
  const progressRef = useRef<CircularProgressCounterMethodsType | null>(null);
  const { html, hasContent, onChange } = useEditorState();
  const data = useSelector(contentStore.selectors.dataById(resourceId));

  const [isInProgress, setIsInProgress] = useState(false);

  const handleAdd = useCallback(() => {
    if (!hasContent) {
      return;
    }
    onAdd?.(trimHtml(html));

    progressRef.current?.start();
    setIsInProgress(true);
  }, [onAdd, hasContent, html]);

  useEffect(() => {
    if (data && progressRef.current) {
      progressRef.current.stop();
      setIsInProgress(false);
      editorRef.current?.clear({ focus: true });
    }
  }, [data]);

  return (
    <Card
      variant="soft"
      size="sm"
      sx={{
        borderRadius: 26,
        ...sx,
      }}
      {...rest}
    >
      <CardActions sx={{ minHeight: 32, '--Card-padding': 0, alignItems: 'flex-end' }}>
        <Box display="flex" flexDirection="column" gap={1} ml={0.5} my={0.5} fontSize={16} flex={1} alignItems="stratch">
          <EditorInput ref={editorRef} onChange={onChange} placeholder="Add new note" onSubmit={handleAdd} disabled={isInProgress} />
        </Box>
        <ButtonGroup
          variant="soft"
          color="neutral"
          spacing={1}
          sx={{
            '--ButtonGroup-radius': '50%',
            '--ButtonGroup-separatorSize': 0,
          }}
        >
          <IconButton onClick={handleAdd} sx={{ my: -0.25 }} disabled={!hasContent || isInProgress}>
            <Icon name="plus" color="inherit" />
            <CircularProgressCounter
              ref={progressRef}
              color="primary"
              size="md"
              sx={{
                position: 'absolute',
              }}
            />
          </IconButton>
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default memo(NewNote);
