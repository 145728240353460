import { useMemo } from 'react';
import useSubscriptionConfig from 'widgets/Subscription/model/useSubscriptionConfig';

interface PeriodDataType {
  id: 'monthly' | 'yearly';
  name: string;
  nameShort: string;
  visible: boolean;
  planIds: string[];
}

function usePlan(planId: string) {
  const { plans, periods } = useSubscriptionConfig();

  return useMemo(() => {
    if (!planId) {
      return {};
    }
    const plan = plans.find((item) => item.identity.planId === planId);
    const period = periods.find((item) => item.planIds.includes(planId)) as PeriodDataType;
    return {
      plan,
      period,
    };
  }, [planId]);
}

export default usePlan;
