import { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { useGetOpenLink } from 'hooks';

import { dispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as subscriptionStore from 'widgets/Subscription/store';

import MenuButton from 'components/UIBar/elements/MenuButton';

import Icon from 'ui/Icon';

import SubscriptionBilling from 'widgets/Subscription/Billing';
import SubscriptionPricing from 'widgets/Subscription/Pricing';

type ThemeSwitcherProps = {
  isSlim?: boolean;
};

const BottomPanel = (props: ThemeSwitcherProps) => {
  const { isSlim } = props;

  const openLink = useGetOpenLink();

  const userProfile = useSelector(userStore.selectors.getMy);
  const isOpeningPortal = useSelector(subscriptionStore.selectors.isOpeningPortal);

  const handleAbout = useCallback(() => {
    openLink('https://iki.ai/');
  }, []);

  const handleLogout = useCallback(() => {
    dispatcher.auth.logOut();
  }, []);

  if (isSlim) {
    return null;
  }

  return (
    <>
      <MenuButton
        style={styles.colorSchemeItem}
        onPress={handleAbout}
        text="About iki.ai"
        isSlim={isSlim}
        isSelected={false}
        iconRight={
          <Icon
            family="kit"
            name="ikicopilot"
            fw
            size="xl"
            color="icon"
            sx={{
              width: 25,
              height: 22,
              mt: -0.25,
            }}
          />
        }
      />
      <SubscriptionPricing
        layout="modal"
        modalOpenerTextProperty="text"
        modalOpenerClickProperty="onPress"
        modalOpener={
          <MenuButton
            style={{ justifyContent: 'space-between' }}
            iconRight={<Icon name="money-bills-simple" fw size="lg" weight="light" color="icon" />}
            isSlim={isSlim}
            isSelected={false}
          />
        }
      />
      <SubscriptionBilling
        buttonTextProperty="text"
        buttonClickProperty="onPress"
        buttonElement={
          <MenuButton
            style={{ justifyContent: 'space-between' }}
            disabled={isOpeningPortal}
            iconRight={
              <Icon
                name={isOpeningPortal ? 'spinner-third' : 'credit-card'}
                fw
                size="lg"
                weight="light"
                color="icon"
                animate={isOpeningPortal ? 'spin' : undefined}
              />
            }
            isSlim={isSlim}
            isSelected={false}
          />
        }
      />
      {userProfile && (
        <MenuButton
          style={styles.colorSchemeItem}
          onPress={handleLogout}
          iconRight={<Icon name="arrow-right-from-bracket" fw size="lg" weight="light" color="icon" />}
          text="Logout"
          isSlim={isSlim}
          isSelected={false}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  colorSchemeItem: {
    justifyContent: 'space-between',
  },
});

export default memo(BottomPanel);
