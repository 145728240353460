import { type FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, DialogActions, Sheet, Typography } from '@mui/joy';

import Icon from 'ui/Icon';

import { useDispatcher } from 'store/utils/redux/hooks';
import StripeForm from 'widgets/StripeForm';
import usePaymentState from 'widgets/StripeForm/model/usePaymentState';

import * as selectors from '../store/selectors';

type SubscriptionContentProps = {
  planId: string;
  promoCode?: string | null;
  trial?: boolean;
};

const SubscriptionContent: FC<SubscriptionContentProps> = (props) => {
  const { planId, promoCode, trial } = props;
  const dispatcher = useDispatcher();

  const isProcessing = useSelector(selectors.isProcessing);

  const { addressComplete, cardComplete, onCreatePaymentMethod, loadingStripe, retrievedPayment } = usePaymentState({
    planId,
    promoCode,
    hasTrial: trial || false,
    onSubmitPayment: dispatcher.subscription.subscribeStripeElements,
  });

  const cardIcon = useMemo(() => {
    if (!retrievedPayment) {
      return null;
    }
    if (retrievedPayment?.cardType === 'visa') {
      return <Icon family="brands" name="cc-visa" size="2x" color="icon" fontSize="1.5rem" />;
    }
    if (retrievedPayment?.cardType === 'mastercard') {
      return <Icon family="brands" name="c-mastercard" size="2x" color="icon" fontSize="1.5rem" />;
    }
    return <Icon weight="solid" name="credit-card-front" size="2x" color="icon" fontSize="1.5rem" />;
  }, [retrievedPayment]);

  return (
    <>
      <Box
        sx={{
          height: '100%',
          overflow: 'auto',
          pl: 0.5,
          pr: 1,
          ml: -0.5,
          mr: -2,
          '&': {
            overflowY: 'scroll',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '0.5rem',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent',
              borderRadius: '0.5rem',
              border: '2px solid transparent',
              backgroundClip: 'content-box',
            },
            '&:hover::-webkit-scrollbar-thumb:hover': {
              backgroundColor: (theme) => theme.palette.primary.softDisabledColor,
            },
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: (theme) => theme.palette.background.level3,
            },
          },
          '& .StripeElement': {
            my: 0.75,
          },
        }}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="baseline" mb={2}>
          <Typography fontWeight={500}>Payment information</Typography>
          <Typography fontSize={12}>Powered by Stripe</Typography>
        </Box>
        {retrievedPayment && (
          <Sheet
            variant="outlined"
            sx={{
              px: 1,
              pt: 0.75,
              pb: 0.75,
              borderRadius: (theme) => theme.radius.md,
            }}
          >
            <Box display="flex" gap={2} alignItems="center">
              {cardIcon}
              <Typography>
                **** **** **** {retrievedPayment.last4} {retrievedPayment.expMonth} / {retrievedPayment.expYear}
              </Typography>
            </Box>
          </Sheet>
        )}
        {!retrievedPayment && <StripeForm isLoading={isProcessing} />}
      </Box>
      <DialogActions sx={{ pt: 0 }}>
        <Box display="flex" justifyContent="center" flex={1} gap={1}>
          <Button
            disabled={!(addressComplete && cardComplete) || loadingStripe}
            loading={isProcessing}
            color="primary"
            onClick={onCreatePaymentMethod}
          >
            Proceed to payment
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default SubscriptionContent;
