import { createAction } from '@reduxjs/toolkit';

import type { PaginationType, RagMaterialType, SearchItemType } from 'app/entities';

export const triggerSearch = createAction('search/trigger', () => ({
  payload: {
    triggerReload: true,
  },
}));

export const clear = createAction('search/clear');

export const setFilterTypeValue = createAction('search/setFilterTypeValue', (id: string | string[], triggerReload = true) => ({
  payload: {
    id,
    triggerReload,
  },
}));

export const toggleFilterTypeValue = createAction('search/toggleFilterTypeValue', (id: string | string[], triggerReload = true) => ({
  payload: {
    id,
    triggerReload,
  },
}));

export const setFilterText = createAction('search/setFilterText', (value: string, triggerReload = true, immediateUpdate = false) => ({
  payload: {
    value,
    triggerReload,
    immediateUpdate,
  },
}));

export const setFilterTags = createAction('search/setFilterTags', (tags?: string | string[] | null, triggerReload = true) => {
  let value: string[] = [];
  if (Array.isArray(tags) && tags.length > 0) {
    value = [...new Set(tags)];
  }
  if (typeof tags === 'string' && tags.length > 0) {
    value = [tags];
  }
  return {
    payload: {
      value,
      triggerReload,
    },
  };
});

export const setMethod = createAction(
  'search/setMethod',
  (payload: {
    value: 'GlobalFullText' | 'GlobalTitle' | 'LibraryFullText' | 'LibraryTitle' | 'Web' | 'Research' | string;
    triggerReload?: boolean;
  }) => ({
    payload: {
      value: payload.value,
      triggerReload: payload.triggerReload || true,
    },
  }),
);

export const loadNextPage = createAction(
  'search/loadNextPage',
  (
    payload: {
      reload?: boolean;
      setMethod?: 'GlobalFullText' | 'GlobalTitle' | 'LibraryFullText' | 'LibraryTitle' | 'Web' | 'Research' | string;
    } = {},
  ) => ({
    payload,
  }),
);

export const loadNextPageDone = createAction(
  'search/loadNextPageDone',
  (reload = false, status: 'has-results' | 'no-results' | 'nothing-to-search' | 'error' | null = null) => ({
    payload: {
      reload,
      status,
    },
  }),
);

export const addPage = createAction('search/setPage', (items: SearchItemType[], paginationInfo: PaginationType, reload = false) => ({
  payload: {
    items,
    paginationInfo,
  },
  meta: {
    reload,
  },
}));

export const setRagMaterial = createAction('search/setRagMaterial', (items: RagMaterialType[] | null) => ({
  payload: {
    items,
  },
}));

export const logOut = createAction('search/logout');
