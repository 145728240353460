import React, { memo, useCallback } from 'react';
import { Box, Typography } from '@mui/joy';
import { Virtuoso } from 'react-virtuoso';

import { useSelector } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';
import * as askStore from 'store/nodes/ask';

import Card from 'widgets/Card';

const SearchResult = () => {
  const scope = useSelector(askStore.selectors.scope);
  const hasItems = useSelector(
    copilotStore.selectors.hasItems([{ type: 'web' }, { type: 'research' }, { type: 'library-fulltext' }, { type: 'global-fulltext' }]),
  );
  const sequence = useSelector(askStore.selectors.sequence);

  const renderItem = useCallback(
    (args: any) => {
      const [highlight] = args?.item?.searchMeta?.highlights || [];
      const extraData: Record<string, string> = {};
      if (highlight) {
        extraData.description = highlight;
      }

      return (
        <Card
          id={args.item.id}
          type={args.item.type}
          descriptionLineLimit={4}
          hasDescription
          hasAuthor
          hasSummary
          hasHighlight
          hasLink
          hasTags
          hasToCollection
          hasIndexed
          hasRemove={scope === 'library-fulltext'}
          hasQueue={['global-fulltext'].includes(scope)}
          sx={{
            mx: 1,
            mb: 2,
          }}
        />
      );
    },
    [scope],
  );

  return (
    <>
      {!hasItems && sequence.length === 0 && (
        <Box display="flex" flexDirection="column" flex={1} justifyContent="center" alignItems="center">
          <Typography color="neutral" fontSize={15}>
            Ask something
          </Typography>
        </Box>
      )}
      {hasItems && sequence.length === 0 && (
        <Box display="flex" flexDirection="column" flex={1} justifyContent="center" alignItems="center">
          <Typography color="neutral" fontSize={15}>
            Nothing found for your question
          </Typography>
        </Box>
      )}
      {sequence.length > 0 && (
        <Box
          sx={{
            ml: 1,
            mt: 1,
            mb: 1,
            height: '100%',
            '[data-testid="virtuoso-scroller"]': {
              overflowY: 'scroll',
              overflowX: 'hidden',
              '&::-webkit-scrollbar': {
                width: '0.5rem',
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent',
                borderRadius: '0.5rem',
                border: '2px solid transparent',
                backgroundClip: 'content-box',
              },
              '&:hover::-webkit-scrollbar-thumb:hover': {
                backgroundColor: (theme) => theme.palette.primary.softDisabledColor,
              },
              '&:hover::-webkit-scrollbar-thumb': {
                backgroundColor: (theme) => theme.palette.background.level3,
              },
            },
          }}
        >
          <Virtuoso
            data={sequence}
            totalCount={sequence?.length}
            increaseViewportBy={{ top: 200, bottom: 500 }}
            itemContent={(_, item) => renderItem({ item })}
          />
        </Box>
      )}
    </>
  );
};

export default memo(SearchResult);
