import { type FC, memo, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/joy';

interface LoadingComponentProps {
  isLoading: boolean;
  initialShow?: boolean;
}

const LoadingComponent: FC<LoadingComponentProps> = ({ isLoading, initialShow }) => {
  const [visible, setVisible] = useState(initialShow || false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (isLoading) {
      timer = setTimeout(() => {
        setVisible(true);
      }, 2000);
    } else {
      setVisible(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isLoading]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 'calc(100vh - 160px)',
        opacity: visible ? 1 : 0,
        transition: 'opacity 300ms ease-in-out',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default memo(LoadingComponent);
