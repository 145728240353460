import { Box, Button, Typography } from '@mui/joy';
import Icon from 'ui/Icon';
import useSocialAuth from 'hooks/useSocialAuth';
import { memo, useCallback } from 'react';

const SocialAuthToast = () => {
  const { linkedIn, google } = useSocialAuth();

  const handleGoogleClick = useCallback(() => {
    google();
  }, []);

  const handleLinkedInClick = useCallback(() => {
    linkedIn();
  }, []);

  return (
    <Box textAlign="center" pl={1.5} pt={1.5} pb={2}>
      <Typography fontWeight={300}>
        Please sign in with your
        <br />
        social account
      </Typography>
      <Box display="grid" mt={2} gap={1} justifyContent="center">
        <Button
          size="md"
          onClick={handleGoogleClick}
          variant="soft"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'var(--joy-palette-neutral-200)',
            '&:hover': {
              backgroundColor: 'var(--joy-palette-neutral-300)',
            },
          }}
        >
          <Icon name="google" fw family="brands" size="lg" color="var(--joy-palette-background-body)" marginRight={1} />
          <Typography
            sx={{
              color: 'var(--joy-palette-background-body)',
            }}
            fontSize={16}
            fontWeight={400}
          >
            Continue with Google
          </Typography>
        </Button>
        <Button
          size="md"
          onClick={handleLinkedInClick}
          variant="soft"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'var(--joy-palette-neutral-200)',
            '&:hover': {
              backgroundColor: 'var(--joy-palette-neutral-300)',
            },
          }}
        >
          <Icon name={'Linkedin'.toLowerCase()} fw family="brands" size="lg" color="var(--joy-palette-background-body)" marginRight={1} />
          <Typography
            sx={{
              color: 'var(--joy-palette-background-body)',
            }}
            fontSize={16}
            fontWeight={400}
          >
            Continue with Linkedin
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default memo(SocialAuthToast);
