import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useRoute } from 'navigation/hooks';

import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

import PanelsBar from 'widgets/PanelsBar';

import useIsMy from '../model/useIsMy';
import useIsEditable from '../model/useIsEditable';
import useIsLoading from '../model/useIsLoading';

import EditorProcessor, { type ContentCommandMethods } from '../components/EditorProcessor';
import CopilotConnector from '../components/CopilotConnector';
import Viewer from '../components/Viewer';
import LoadingComponent from '../components/LoadingComponent';

const Layout = () => {
  const route = useRoute<'Note'>();

  const editorRef = useRef<ContentCommandMethods>(null);

  const id = route.params?.id === 'create' ? 'create' : Number(route.params?.id);
  const selectedContext = useSelector(noteStore.selectors.selectedContext);
  const isMy = useIsMy(id);
  const isEditable = useIsEditable(id);
  const isLoading = useIsLoading(id);

  const handleCopyToText = useCallback((text: string) => {
    editorRef.current?.insert(text);
  }, []);

  const contentMeta = useMemo(
    () => ({
      label: 'Note',
      value: 'note',
    }),
    [],
  );

  const contentRender = useMemo(() => {
    if (isMy && isEditable && isLoading) {
      return <LoadingComponent isLoading={isLoading} />;
    }
    if ((isMy && isEditable) || id === 'create') {
      return <EditorProcessor ref={editorRef} id={id} autofocus={id === 'create'} />;
    }
    return (
      <Viewer
        id={id}
        wrapperClassName="tiptap ProseMirror prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full"
        thresholdOffsetPx={1000}
        initialBatchSize={50}
        batchSize={50}
        loaderIndicator={<LoadingComponent isLoading />}
      />
    );
  }, [isLoading, id, EditorProcessor, Viewer, isMy, isEditable]);

  const panelsRender = useMemo(() => {
    const map = {
      note: 'current note',
      'library-fulltext': 'library',
    } as const;
    return [
      {
        label: `AI Copilot by ${map[selectedContext]}`,
        value: 'copilot',
        component: <CopilotConnector id={id} onCopyToText={handleCopyToText} autofocus={id !== 'create'} />,
      },
    ];
  }, [isMy, id, isEditable, selectedContext, handleCopyToText]);

  if (!id) {
    return null;
  }

  return (
    <PanelsBar
      contentMeta={contentMeta}
      content={contentRender}
      panels={panelsRender}
      sx={{
        '@media (max-width: 767px)': {
          mx: -2,
        },
      }}
    />
  );
};

export default memo(Layout);
