import { memo, useCallback, type FC, type MouseEventHandler } from 'react';
import { List, ListItem, ListItemButton, ListItemContent, Tabs, TabList, Tab, type TabsProps } from '@mui/joy';
import Icon from 'ui/Icon';

const items = [
  { id: 'Profile', label: 'Profile', icon: 'user' },
  { id: 'General', label: 'General', icon: 'gear' },
  { id: 'Plans', label: 'Plans', icon: 'money-check-dollar' },
  // { id: 'Integrations', label: 'Integrations', icon: 'lock' },
  // { id: 'Notification', label: 'Notification', icon: 'bell' },
];

type MenuBarProps = {
  value: string;
  onChange: (value: string) => void;
};

const MenuBar: FC<MenuBarProps> = (props) => {
  const { value, onChange } = props;

  const handleListItemClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      if (!event?.currentTarget || !(event.currentTarget instanceof HTMLElement)) {
        return;
      }
      const id = event.currentTarget.getAttribute('itemID');
      if (!id) {
        return;
      }
      onChange?.(id);
    },
    [onChange],
  );

  const handleTabChange = useCallback<NonNullable<TabsProps['onChange']>>(
    (_, id) => {
      if (!id) {
        return;
      }
      onChange?.(id as string);
    },
    [onChange],
  );

  return (
    <>
      <List
        sx={{
          flex: 1,
          fontSize: 13,
          '--List-gap': '0.2rem',
          '@media (max-width: 767px)': {
            display: 'none',
          },
        }}
      >
        {items.map((item) => (
          <ListItem
            sx={{
              '--ListItem-radius': (theme) => theme.radius.xl,
            }}
            key={item.id}
          >
            <ListItemButton selected={value === item.id} itemID={item.id} onClick={handleListItemClick}>
              <Icon name={item.icon} fw weight="light" color="icon" size="lg" />
              <ListItemContent>{item.label}</ListItemContent>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Tabs
        value={value}
        onChange={handleTabChange}
        sx={{
          bgcolor: 'transparent',
          mt: 0.5,
          mb: 2,
          '--joy-palette-background-surface': 'var(--joy-palette-background-level1)',
          '@media (min-width: 768px)': {
            display: 'none',
          },
        }}
      >
        <TabList
          disableUnderline
          sx={{
            '--List-radius': '1.125rem',
            overflow: 'hidden',
          }}
        >
          {items.map((item) => (
            <Tab key={item.id} variant="soft" itemID={item.id} disableIndicator value={item.id} sx={{ flex: 1 }}>
              <Icon name={item.icon} fw weight="light" color="icon" size="lg" />
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </>
  );
};

export default memo(MenuBar);
