import { useMemo, useState } from 'react';
import { stripTags } from 'utils';

const useEditorState = (initialValue: string | null = null) => {
  const [value, setValue] = useState<string | null>(initialValue);

  return useMemo(() => {
    const rawText = stripTags(value);
    const text = rawText.trim();
    return {
      onChange: setValue,
      html: value || '',
      text,
      hasContent: text.length > 0,
    };
  }, [value]);
};

export default useEditorState;
