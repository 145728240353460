import { useEffect, useState } from 'react';
import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

const useIsLoading = (id: number | 'create') => {
  const [, setIsInit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(id !== 'create');

  const isStoreLoading = useSelector((state) => (id === 'create' ? false : noteStore.selectors.isLoading({ noteId: id })(state)));

  useEffect(() => {
    setIsInit((prevInitState) => {
      if (!prevInitState) {
        return true;
      }
      setIsLoading((prevLoadingState) => {
        if (!prevLoadingState) {
          return false;
        }
        return isStoreLoading;
      });
      return true;
    });
  }, [isStoreLoading]);

  if (id === 'create') {
    return false;
  }

  return isLoading;
};

export default useIsLoading;
