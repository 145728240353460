import { memo } from 'react';
import { Box, type BoxProps, useColorScheme } from '@mui/joy';
import { CardElement, AddressElement } from '@stripe/react-stripe-js';
import { isEqual } from 'lodash';

export type StripeFormProps = {
  isLoading?: boolean;
  isSkipping?: boolean;
  slotsProps?: {
    cardElementContainer?: BoxProps;
    addressElementContainer?: BoxProps;
  };
} & BoxProps;

const StripeForm = (props: StripeFormProps) => {
  const { isLoading, isSkipping, slotsProps, ...rest } = props;

  const { colorScheme } = useColorScheme();

  const CardElementOptions = {
    disabled: isLoading || isSkipping,
    hidePostalCode: true,
    style: {
      base: {
        color: colorScheme === 'dark' ? '#f1f1f1' : '#181817',
        fontWeight: '500',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: colorScheme === 'dark' ? '#9f9f9f' : '#555554',
        },
      },
    },
  };

  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        pointerEvents: isSkipping ? 'none' : 'all',
        cursor: isSkipping ? 'not-allowed' : 'pointer',
        ...rest.sx,
      }}
    >
      <Box {...slotsProps?.cardElementContainer}>
        <CardElement options={CardElementOptions} />
      </Box>
      <Box
        {...slotsProps?.addressElementContainer}
        sx={{
          pointerEvents: isLoading ? 'none' : 'all',
          cursor: isLoading ? 'not-allowed' : 'pointer',
          ...slotsProps?.addressElementContainer?.sx,
        }}
      >
        <AddressElement
          options={{
            mode: 'billing',
          }}
        />
      </Box>
    </Box>
  );
};

export default memo(StripeForm, (prevProps, nextProps) => isEqual(prevProps, nextProps));
