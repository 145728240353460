import React from 'react';
import htmlReactParser from 'html-react-parser';

/**
 * Options for the splitHtmlByRootElements function.
 * @template Convert - If true, convert to React elements instead of returning strings.
 */
interface SplitOptions<Convert extends boolean> {
  convertToReactElements?: Convert;
}

/**
 * Return type depending on whether convertToReactElements is true or false.
 * If Convert = true -> Promise<React.ReactElement[]>
 * If Convert = false -> Promise<string[]>
 */
type SplitReturn<Convert extends boolean> = Convert extends true ? React.ReactElement[] : string[];

/**
 * Asynchronously splits the given HTML string into root-level elements using a Web Worker.
 * If `convertToReactElements` is set to true, it returns React elements instead of strings.
 *
 * @template Convert - Boolean flag to determine the return type.
 * @param {string} html - The HTML string to split.
 * @param {SplitOptions<Convert>} [options] - Options to configure the behavior.
 * @returns {Promise<SplitReturn<Convert>>} A promise that resolves with either an array of strings or React elements.
 */
export default function splitHtmlByRootElements<Convert extends boolean = false>(
  html: string,
  options?: SplitOptions<Convert>,
): Promise<SplitReturn<Convert>> {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const worker = new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' });

    worker.onmessage = (e: MessageEvent) => {
      const data = e.data as string[];
      worker.terminate();

      if (options?.convertToReactElements) {
        const elements = data.map((block) => htmlReactParser(block));
        resolve(elements as SplitReturn<Convert>);
      } else {
        resolve(data as SplitReturn<Convert>);
      }
    };

    worker.onerror = (err) => {
      reject(err);
      worker.terminate();
    };

    worker.postMessage(html);
  });
}
