import { type FC, memo, type SetStateAction, useCallback, useState } from 'react';
import { Box, Button, Typography } from '@mui/joy';
import { type Stripe } from '@stripe/stripe-js';

import Icon from 'ui/Icon';

import { useSelector } from 'store/utils/redux/hooks';
import * as onboardingStore from 'widgets/Onboarding/store';
import StripeForm from 'widgets/StripeForm';
import usePaymentState from 'widgets/StripeForm/model/usePaymentState';
import PromoInput, { type OnPromoInputEventHandler, type PromoDataType } from 'widgets/Subscription/ui/PromoInput';
import Alert from 'components/Alert';
import BillingForm from './ui/BillingForm';

export type StripeContainerProps = {
  planId: string;
  onPlanChange: (event: { target: { value: SetStateAction<string> } }) => void;
  onTrialStart: (params: { planId: string; promoCode?: string | null; paymentMethodId: string; stripe: Stripe; }) => void;
  isSkipLoading?: boolean;
};

const StripeContainer: FC<StripeContainerProps> = (props) => {
  const { planId, onPlanChange, onTrialStart, isSkipLoading } = props;
  const isLoading = useSelector(onboardingStore.selectors.isLoading);
  const [promo, setPromo] = useState<(PromoDataType & { code: string }) | null>(null);

  const { addressComplete, cardComplete, onCreatePaymentMethod } = usePaymentState({
    planId,
    promoCode: promo?.code || null,
    onSubmitPayment: onTrialStart,
  });

  const handlePromoEvent: OnPromoInputEventHandler = useCallback((event) => {
    if (event.type === 'applied') {
      setPromo({ ...event.data, code: event.code });
    }
    if (event.type === 'reset') {
      setPromo(null);
    }
    if (event.type === 'error') {
      Alert.error(event.error);
    }
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="column" mt={2} width="100%">
        <Box>
          <BillingForm isSkipLoading={isSkipLoading} isDisabled={isLoading} planId={planId} onPlanChange={onPlanChange} promo={promo} />
          <PromoInput isApplied={!!promo} onEvent={handlePromoEvent} sx={{ mt: 1.5 }} />
        </Box>
        <StripeForm isSkipping={isSkipLoading} isLoading={isLoading} slotsProps={{ addressElementContainer: { mt: 1.5 } }} mt={5} />
      </Box>
      <Button
        size="lg"
        color="neutral"
        variant="soft"
        disabled={!(addressComplete && cardComplete) || isSkipLoading}
        loading={isLoading}
        onClick={onCreatePaymentMethod}
        endDecorator={<Icon name="arrow-right" color="inherit" />}
        sx={{
          mt: 4,
          minWidth: '16rem',
          '--Button-minHeight': '3rem',
        }}
      >
        <Typography sx={{ color: 'inherit' }}>Start free trial</Typography>
      </Button>
    </>
  );
};

export default memo(StripeContainer);
