import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ScopeType = 'web' | 'research' | 'global-fulltext' | 'library-fulltext';

export type SequenceType = {
  type: 'Material' | 'RagMaterial' | 'Collection' | 'Unknown';
  id: number | string;
};

export type AskStore = {
  sequence: SequenceType[];
  scope: ScopeType;
  query: string;
};

const initialState: AskStore = {
  sequence: [],
  scope: (localStorage?.getItem('ask/scope') || 'web') as ScopeType,
  query: '',
};

const slice = createSlice({
  name: 'ask',
  initialState,
  reducers: {
    setScope: (state, action: PayloadAction<{ scope: ScopeType }>) => {
      state.scope = action.payload.scope;
      localStorage?.setItem('ask/scope', action.payload.scope);
    },
    setQuery: (state, action: PayloadAction<{ query: string }>) => {
      state.query = action.payload.query;
    },
    setSequence: (state, action: PayloadAction<{ sequence: SequenceType[] }>) => {
      state.sequence = action.payload.sequence;
    },
    addSequence: (state, action: PayloadAction<{ sequence: SequenceType[] }>) => {
      state.sequence.push(...action.payload.sequence);
    },
    searchClear: () => undefined,
  },
});

export const { reducer, actions } = slice;
