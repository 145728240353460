import { type ReactNode, createContext } from 'react';

export type ConfigType = {
  answer: {
    actionsBar?: ('Reload' | 'ToClipboard' | 'ToNote' | ReactNode)[];
  };
  input?: {
    autofocus?: boolean;
  };
};

export const ConfigContext = createContext<ConfigType | undefined>(undefined);
