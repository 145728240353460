import { memo, type MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $getSelection, $isRangeSelection, $isRootOrShadowRoot } from 'lexical';
import { $createHeadingNode, type HeadingTagType, $isHeadingNode } from '@lexical/rich-text';

import { $setBlocksType } from '@lexical/selection';
import { Button, Dropdown, IconButton, Menu, MenuButton, MenuItem, Typography } from '@mui/joy';
import Icon from 'ui/Icon';
import { $findMatchingParent } from '@lexical/utils';

type HeadingPanelProps = {
  editor: ReturnType<typeof useLexicalComposerContext>[0];
};

const HeadingPanel = ({ editor }: HeadingPanelProps) => {
  const [activeHeading, setActiveHeading] = useState<HeadingTagType | null>(null);

  useEffect(() => {
    const unregisterListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
          setActiveHeading(null);
          return;
        }
        const anchorNode = selection.anchor.getNode();
        const element =
          anchorNode.getKey() === 'root'
            ? anchorNode
            : $findMatchingParent(anchorNode, (e) => {
                const parent = e.getParent();
                return parent !== null && $isRootOrShadowRoot(parent);
              });

        if ($isHeadingNode(element)) {
          setActiveHeading(element.getTag() as HeadingTagType);
        } else {
          setActiveHeading(null);
        }
      });
    });

    return () => {
      unregisterListener();
    };
  }, [editor]);

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const handleHeadingClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const headingType = event.currentTarget.value as HeadingTagType;
      editor.update(() => {
        const selection = $getSelection();
        if (activeHeading === headingType) {
          formatParagraph();
          setActiveHeading(null);
        } else {
          $setBlocksType(selection, () => $createHeadingNode(headingType));
          setActiveHeading(headingType);
        }
      });
    },
    [editor, activeHeading],
  );

  // {['h1', 'h2', 'h3', 'h4'].map((type) => (
  //   <IconButton
  //     key={type}
  //     value={type}
  //     aria-pressed={activeHeading === type}
  //     onClick={handleHeadingClick}
  //   >
  //     <Icon name={type} fw weight="regular" color="inherit" />
  //   </IconButton>
  // ))}

  const availableHeading = useMemo(
    () => [
      {
        value: 'h1',
        label: 'Heading 1',
      },
      {
        value: 'h2',
        label: 'Heading 2',
      },
      {
        value: 'h3',
        label: 'Heading 3',
      },
      {
        value: 'h4',
        label: 'Heading 4',
      },
    ],
    [],
  );

  const menu = useMemo(
    () => (
      <Menu>
        {availableHeading.map((item) => (
          <MenuItem
            key={item.value}
            selected={item.value === ''}
            itemID={`${item.value}`}
            // onClick={handleSelect}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    ),
    [availableHeading],
  );

  return (
    <>
      {['h2', 'h3', 'h4'].map((type) => (
        <IconButton key={type} value={type} aria-pressed={activeHeading === type} onClick={handleHeadingClick}>
          <Icon name={type} fw weight="regular" color="inherit" />
        </IconButton>
      ))}
      {/* <Dropdown> */}
      {/*   <MenuButton */}
      {/*     slots={{ root: Button }} */}
      {/*     slotProps={{ */}
      {/*       root: { */}
      {/*         variant: 'plain', */}
      {/*         color: 'neutral', */}
      {/*         size: 'sm', */}
      {/*         // disabled: isAnswerTyping || availableModels.length === 1, */}
      {/*       }, */}
      {/*     }} */}
      {/*   > */}
      {/*     <Typography fontSize={16} fontWeight="400" sx={{ color: 'inherit' }}> */}
      {/*       {activeHeading} */}
      {/*     </Typography> */}
      {/*     <Icon name="circle-chevron-down" size="1x" fw weight="regular" color="inherit" ml={1} /> */}
      {/*   </MenuButton> */}
      {/*   {menu} */}
      {/* </Dropdown> */}
    </>
  );
};

export default memo(HeadingPanel);
