import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';
import type { NoteKey } from 'app/entities';

const cacheKeyCreator = (state: DefaultRootState, key: NoteKey) => `${key.noteId}`;

const noteEditableSelector = createCachedSelector(
  (state: DefaultRootState, key: NoteKey) => state.note.data[key.noteId]?.isEditable || false,
  (result) => result,
)(cacheKeyCreator);

export default (key: NoteKey) => (state: DefaultRootState) => {
  return noteEditableSelector(state, key);
};
