import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/joy';

import { useHasSession } from 'hooks';

import * as userStore from 'store/nodes/user';

import AdaptivePage from 'components/AdaptivePage';
import AddNoteButton from 'components/AddNoteButton';
import PlusButton from 'components/PlusButton';

import { CopilotUtilityBar, CopilotShowToggle, CopilotModelSelect } from 'widgets/Copilot';
import EditorStatusContainer from 'widgets/Novel/ui/EditorStatusContainer';

import ActionsBar from './ui/ActionsBar';
import ContextSelect from './components/ContextSelect';
import IndexingStatus from './components/IndexingStatus';
import TooLongStatus from './components/TooLongStatus';

import Layout from './layouts/Layout';

const Note = () => {
  const hasSession = useHasSession();
  const userData = useSelector(userStore.selectors.getByLogin('my'));

  const pagePaddingHorizontalScheme = useMemo(
    () => ({
      320: 16,
    }),
    [],
  );

  if (hasSession && !userData) {
    return null;
  }

  return (
    <AdaptivePage
      desktopHeaderLeftPanel={<ActionsBar />}
      desktopHeaderMiddlePanel={
        <Box display="flex" flexDirection="row" alignItems="center" gap={2} alignSelf="flex-end">
          <IndexingStatus />
          <TooLongStatus />
          <EditorStatusContainer />
          <CopilotShowToggle mr={-1} />
        </Box>
      }
      desktopHeaderRightPanel={
        <CopilotUtilityBar
          pl={1}
          left={
            <>
              <CopilotUtilityBar.Item canHidden>
                <CopilotModelSelect ml={2} />
              </CopilotUtilityBar.Item>
              <CopilotUtilityBar.Item canHidden>
                <ContextSelect />
              </CopilotUtilityBar.Item>
            </>
          }
          right={
            <>
              {/* <AddNoteButton /> */}
              <PlusButton />
            </>
          }
        />
      }
      bodyMaxWidth={1280}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
      scrollToStart
    >
      <Layout />
    </AdaptivePage>
  );
};

export default Note;
