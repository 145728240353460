import { type FC, type ReactElement, memo, useEffect, useRef, useState, Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/joy';

import * as noteStore from 'store/nodes/note';
import { splitHtmlByRootElements } from 'utils';
import useIsLoading from 'screens/Note/model/useIsLoading';

export type ViewerProps = {
  id: number;
  wrapperClassName?: string;
  initialBatchSize?: number;
  batchSize?: number;
  thresholdOffsetPx?: number;
  loaderIndicator?: ReactElement;
  onPrepared?: () => void;
};

const Viewer: FC<ViewerProps> = (props) => {
  const { id, wrapperClassName, initialBatchSize = 50, batchSize = 50, thresholdOffsetPx = 0, loaderIndicator, onPrepared } = props;
  const data = useSelector(noteStore.selectors.dataById(id));
  const isLoading = useIsLoading(id);

  const [isBlocksPrepared, setIsBlocksPrepared] = useState<boolean>(false);
  const [allBlocks, setAllBlocks] = useState<ReactElement[]>([]);
  const [displayedBlocks, setDisplayedBlocks] = useState<ReactElement[]>([]);

  const [hasMore, setHasMore] = useState<boolean>(true);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  const isPrepared = useMemo(() => !isLoading && isBlocksPrepared, [isLoading, isBlocksPrepared]);

  useEffect(() => {
    if (!data?.text) {
      return;
    }
    splitHtmlByRootElements(data.text, { convertToReactElements: true }).then((blocks) => {
      setAllBlocks(blocks);
      setIsBlocksPrepared(true);
      if (blocks.length > initialBatchSize) {
        setDisplayedBlocks(blocks.slice(0, initialBatchSize));
        setHasMore(true);
      } else {
        setDisplayedBlocks(blocks);
        setHasMore(false);
      }
      onPrepared?.();
    });
  }, [data, initialBatchSize, onPrepared]);

  useEffect(() => {
    if (!hasMore) {
      return undefined;
    }
    if (!sentinelRef.current) {
      return undefined;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setDisplayedBlocks((prev) => {
            const currentLength = prev.length;
            const nextLength = currentLength + batchSize;
            const nextBlocks = (allBlocks || []).slice(0, nextLength);
            if (nextBlocks.length >= (allBlocks || []).length) {
              setHasMore(false);
            }
            return nextBlocks;
          });
        }
      },
      {
        root: null,
        rootMargin: `0px 0px ${thresholdOffsetPx}px 0px`,
        threshold: 0.1,
      },
    );

    observer.observe(sentinelRef.current);

    return () => {
      observer.disconnect();
    };
  }, [hasMore, allBlocks, batchSize, thresholdOffsetPx]);

  if (!isPrepared && loaderIndicator) {
    return loaderIndicator;
  }

  if (displayedBlocks.length === 0) {
    return null;
  }

  return (
    <Box
      className={wrapperClassName}
      sx={{
        '& [data-index="0"] > div > *:first-child': {
          marginTop: 0,
        },
        '& [data-type="taskItem"]': {
          pointerEvents: 'none',
          tabindex: 'unset',
        },
      }}
    >
      {displayedBlocks.map((block, index) => (
        <Fragment key={index}>{block}</Fragment>
      ))}
      {hasMore && <div ref={sentinelRef} style={{ height: 1 }} />}
    </Box>
  );
};

export default memo(Viewer);
