import MarkdownIt from 'markdown-it';

const isHtml = (text: string): boolean => {
  const htmlPattern = /^<[^>]+>[\s\S]*<\/[^>]+>$|^(<[^>]+>[\s\S]*<\/[^>]+>)+$/;
  return htmlPattern.test(text.trim());
};

interface PrepareHtmlOptions<Async extends boolean> {
  async?: Async;
}

type PrepareHtmlReturn<Async extends boolean> = Async extends true ? Promise<string | null> : string | null;

function prepareHtml<Async extends boolean = false>(
  data: { text?: string | null } | null,
  options?: PrepareHtmlOptions<Async>,
): PrepareHtmlReturn<Async> {
  if (!data) {
    return (options?.async ? Promise.resolve(null) : null) as PrepareHtmlReturn<Async>;
  }
  if (!data.text) {
    return (options?.async ? Promise.resolve('') : '') as PrepareHtmlReturn<Async>;
  }

  const text = data.text.trim();

  if (isHtml(text)) {
    return (options?.async ? Promise.resolve(text) : text) as PrepareHtmlReturn<Async>;
  }

  if (!options?.async) {
    const md = new MarkdownIt({
      html: false,
      linkify: true,
      typographer: true,
    });
    return md.render(text) as PrepareHtmlReturn<Async>;
  }

  // @ts-ignore
  const worker = new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' });

  return new Promise<string | null>((resolve, reject) => {
    worker.onmessage = (e: MessageEvent) => {
      resolve(e.data.result);
      worker.terminate();
    };
    worker.onerror = (err) => {
      reject(err);
      worker.terminate();
    };
    worker.postMessage({ text });
  }) as PrepareHtmlReturn<Async>;
}

export default prepareHtml;
