import { createRef, type RefObject, memo } from 'react';

import * as api from 'services/api';
import { saveLastRoute } from 'utils';

import { navigate } from 'navigation/methods';

import Search, { type SearchProps } from 'components/Search';
import SearchUser, { type SearchUserProps } from 'components/SearchUser';
import Promo, { type PromoProps } from 'components/Promo';
import Relations, { type RelationsProps } from 'components/Relations';
import JobAdd, { type JobAddProps } from 'screens/Profile/elements/Jobs/elements/JobAdd';
import Skills, { type SkillsProps } from 'screens/Profile/elements/Skills/elements/Skills';

import Modal from './elements/Modal';

import Playlists, { type PlaylistsProps } from './popup/Playlists';

import MenuPlus from './menu/Plus';
import MenuCardActions, { type CardActionsProps } from './menu/CardActions';

import InfoAddMaterialComplete from './info/AddMaterialComplete';
import InfoPlaylistCreated from './info/PlaylistCreated';

const popupSearchRef = createRef<any>();
const popupSearchUserRef = createRef<any>();
const popupPlaylistsRef = createRef<any>();
const popupRelationsRef = createRef<any>();
const popupJobAddRef = createRef<any>();
const popupSkillsRef = createRef<any>();
const popupPromoRef = createRef<any>();

const menuPlusRef = createRef<any>();
const menuCardActions = createRef<any>();

const infoAddMaterialCompleteRef = createRef<any>();
const infoPlaylistCreatedRef = createRef<any>();

const Init = memo(() => (
  <>
    <Modal ref={popupSearchRef} name="Search" layout="infinity">
      <Search />
    </Modal>
    <Modal ref={popupSearchUserRef} name="SearchUser" layout="infinity">
      <SearchUser />
    </Modal>
    <Modal ref={popupPlaylistsRef} name="Playlists" layout="infinity">
      <Playlists />
    </Modal>
    <Modal ref={popupRelationsRef} name="Relations" layout="infinity">
      <Relations />
    </Modal>
    <Modal ref={popupJobAddRef} name="JobAdd" layout="infinity">
      <JobAdd />
    </Modal>
    <Modal ref={popupSkillsRef} name="Skills" layout="infinity">
      <Skills />
    </Modal>
    <Modal ref={popupPromoRef} name="Promo" layout="infinity">
      <Promo />
    </Modal>

    <MenuPlus ref={menuPlusRef} />
    <MenuCardActions ref={menuCardActions} />

    <InfoAddMaterialComplete ref={infoAddMaterialCompleteRef} />
    <InfoPlaylistCreated ref={infoPlaylistCreatedRef} />
  </>
));

class ControllerItem<Context = any> {
  private ref: RefObject<any>;

  constructor(ref: RefObject<any>) {
    this.ref = ref;
  }

  public delay = (ms: number) => {
    return {
      open: (context?: Partial<Context>, callbacks?: { onOpen?: () => void; onClose?: () => void }) => {
        setTimeout(() => {
          this.open(context, callbacks);
        }, ms);
      },
      close: (onClose?: () => void) => {
        setTimeout(() => {
          this.close(onClose);
        }, ms);
      },
    };
  };

  public open = (context?: Partial<Context>, callbacks?: { onOpen?: () => void; onClose?: () => void }) => {
    const hasSession = api.credentials.hasSession();
    if (!hasSession) {
      saveLastRoute();
      navigate('Auth/Start');
      return;
    }
    this.ref?.current?.open(context, callbacks);
    setTimeout(() => {
      callbacks?.onOpen?.();
    }, 400);
  };

  public close = (onClose?: () => void) => {
    this.ref?.current?.close();
    setTimeout(() => {
      onClose?.();
    }, 400);
  };
}

export const controller = {
  popup: {
    search: new ControllerItem<SearchProps>(popupSearchRef),
    searchUser: new ControllerItem<SearchUserProps>(popupSearchUserRef),
    playlists: new ControllerItem<PlaylistsProps>(popupPlaylistsRef),
    relations: new ControllerItem<RelationsProps>(popupRelationsRef),
    jobAdd: new ControllerItem<JobAddProps>(popupJobAddRef),
    skills: new ControllerItem<SkillsProps>(popupSkillsRef),
    promo: new ControllerItem<PromoProps>(popupPromoRef),
  },
  menu: {
    plus: new ControllerItem<{ callingTarget?: HTMLDivElement | any }>(menuPlusRef),
    cardActions: new ControllerItem<CardActionsProps & { callingTarget?: HTMLDivElement | any }>(menuCardActions),
  },
  info: {
    addMaterialComplete: new ControllerItem(infoAddMaterialCompleteRef),
    playlistCreated: new ControllerItem(infoPlaylistCreatedRef),
  },
};

export default {
  Init,
};
