import { type ReactNode, useEffect } from 'react';
import { EditorBubble, useEditor } from 'novel';
import { removeAIHighlight } from 'novel/extensions';

import { Button } from '../../ui/button';
import Magic from '../../ui/icons/magic';
import AISelector from './aiSelector';

type GenerativeMenuSwitchProps = {
  children: ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

const GenerativeMenuSwitch = ({ children, open, onOpenChange }: GenerativeMenuSwitchProps) => {
  const { editor } = useEditor();

  useEffect(() => {
    if (!open) removeAIHighlight(editor);
  }, [open]);

  return (
    <EditorBubble
      tippyOptions={{
        placement: open ? 'bottom-start' : 'top',
        appendTo: document.getElementById('root') as HTMLElement,
        interactive: true,
        onHidden: () => {
          onOpenChange(false);
          editor?.chain().unsetHighlight().run();
        },
      }}
      // bg-background
      className="flex w-fit max-w-[90vw] overflow-hidden rounded-md border border-muted bg-popover shadow-xl"
    >
      {open && <AISelector onOpenChange={onOpenChange} />}
      {!open && (
        <>
          <Button className="gap-1 rounded-none text-blue-500" variant="ghost" onClick={() => onOpenChange(true)} size="sm">
            <Magic className="h-5 w-5" />
            Ask IKI AI
          </Button>
          {children}
        </>
      )}
    </EditorBubble>
  );
};

export default GenerativeMenuSwitch;
