import { useCallback, useMemo, useState } from 'react';
import jwtToken from 'jwt-decode';
import { credentials } from 'services/api';
import { gtm } from 'utils';
import Alert from 'components/Alert';
import useAuth from 'widgets/OAuth/model/useAuth';
import { useDispatcher } from 'store/utils/redux/hooks';

const useSocialAuth = () => {
  const dispatcher = useDispatcher();
  const [isLinkedInProcess, setIsLinkedInProcess] = useState(false);

  const auth = useAuth({
    onSuccess: (result) => {
      credentials.set(result.token.access, result.token.refresh);

      const decodedToken: {
        session_id: number;
        login_type: 'login' | 'register';
      } = jwtToken(result.token.access);

      gtm.dataLayer.push({
        event: decodedToken.login_type,
        method: 'google',
        userid: decodedToken.session_id,
      });
      if (typeof location !== 'undefined') {
        setTimeout(() => {
          location.reload();
        }, 500);
      }
    },
    onFail: (result) => {
      if (result.type === 'error') {
        Alert.error(result.message);
      }
      if (result.type === 'warning') {
        Alert.info(result.message);
      }
    },
  });

  const google = useCallback(() => {
    auth.login('google');
  }, [auth]);

  const linkedIn = useCallback(() => {
    setIsLinkedInProcess(true);
    dispatcher.auth.doOAuth('linkedin');
  }, []);

  const inProcess = useMemo<'google' | 'linkedin' | false>(() => {
    if (auth.inProcess === 'google') {
      return 'google';
    }
    if (isLinkedInProcess) {
      return 'linkedin';
    }
    return false;
  }, [auth.inProcess, isLinkedInProcess]);

  return useMemo(
    () => ({
      google,
      linkedIn,
      inProcess,
    }),
    [google, linkedIn, inProcess],
  );
};

export default useSocialAuth;
