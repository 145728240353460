import type { PromoDataType } from 'widgets/Subscription/ui/PromoInput';
import useSubscriptionConfig from 'widgets/Subscription/model/useSubscriptionConfig';
import { formatCurrency } from 'utils';

/**
 * Represents the cost details when there is no promotional discount.
 */
interface RegularCost {
  type: 'regular';
  monthly: {
    value: number;
    total: number;
    formatted: {
      value: string;
      total: string;
    };
  };
  yearly: {
    value: number;
    total: number;
    formatted: {
      value: string;
      total: string;
    };
  };
}

/**
 * Represents the cost details when a promotional discount is applied.
 */
interface SpecialCost {
  type: 'special';
  discount: number;
  monthly: {
    value: number;
    discountedValue: number;
    total: number;
    discountedTotal: number;
    formatted: {
      value: string;
      discountedValue: string;
      total: string;
      discountedTotal: string;
    };
  };
  yearly: {
    value: number;
    discountedValue: number;
    total: number;
    discountedTotal: number;
    formatted: {
      value: string;
      discountedValue: string;
      total: string;
      discountedTotal: string;
    };
  };
}

/**
 * Represents the cost details for a subscription plan.
 */
type CostItem = RegularCost | SpecialCost;

/**
 * Computes the costs of subscription plans with or without a promotional discount.
 *
 * @param {object} [options] - Additional options.
 * @param {PromoDataType | null} [options.promo] - Optional promotional data containing discount percentage.
 * @returns {Record<string, CostItem>} A map of plan IDs to their cost details.
 */
const useCosts = (options?: { promo?: PromoDataType | null }): Record<string, CostItem> => {
  const { promo } = options || {};
  const subscriptionConfig = useSubscriptionConfig();
  const map: Record<string, CostItem> = {};

  subscriptionConfig.plans.forEach((item) => {
    const { planId } = item.identity;
    const baseValue = item.cost.value || 0;

    const monthlyValue = baseValue;
    const yearlyValue = baseValue * 12;

    const regularMonthlyTotal = monthlyValue * 1;
    const regularYearlyTotal = yearlyValue * 1;

    if (!promo) {
      map[planId] = {
        type: 'regular',
        monthly: {
          value: monthlyValue,
          total: regularMonthlyTotal,
          formatted: {
            value: formatCurrency(monthlyValue),
            total: formatCurrency(regularMonthlyTotal),
          },
        },
        yearly: {
          value: yearlyValue,
          total: regularYearlyTotal,
          formatted: {
            value: formatCurrency(yearlyValue),
            total: formatCurrency(regularYearlyTotal),
          },
        },
      };
    } else {
      const percentOff = promo.percentOff || 0;
      const durationInMonths = promo.durationInMonths || 0;
      const discountMultiplier = (100 - percentOff) / 100;

      const discountedMonthlyValue = monthlyValue * discountMultiplier;
      const discountedYearlyValue = yearlyValue * discountMultiplier;

      const discountedMonthlyTotal = discountedMonthlyValue;

      let discountedYearlyTotal;
      if (durationInMonths >= 12) {
        discountedYearlyTotal = discountedYearlyValue;
      } else {
        discountedYearlyTotal = discountedMonthlyValue * durationInMonths + monthlyValue * (12 - durationInMonths);
      }

      const discount = baseValue - baseValue * (percentOff / 100);

      map[planId] = {
        type: 'special',
        discount,
        monthly: {
          value: monthlyValue,
          discountedValue: discountedMonthlyValue,
          total: regularMonthlyTotal,
          discountedTotal: discountedMonthlyTotal,
          formatted: {
            value: formatCurrency(monthlyValue),
            discountedValue: formatCurrency(discountedMonthlyValue),
            total: formatCurrency(regularMonthlyTotal),
            discountedTotal: formatCurrency(discountedMonthlyTotal),
          },
        },
        yearly: {
          value: yearlyValue,
          discountedValue: discountedYearlyValue,
          total: regularYearlyTotal,
          discountedTotal: discountedYearlyTotal,
          formatted: {
            value: formatCurrency(yearlyValue),
            discountedValue: formatCurrency(discountedYearlyValue),
            total: formatCurrency(regularYearlyTotal),
            discountedTotal: formatCurrency(discountedYearlyTotal),
          },
        },
      };
    }
  });

  return map;
};

export default useCosts;
