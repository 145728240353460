import { useState, useCallback, type ChangeEventHandler } from 'react';
import network from 'lib/network';

/**
 * Интерфейс данных, возвращаемых после успешной валидации промо-кода.
 */
export interface AppliedData {
  valid: boolean;
  percentOff: number;
  durationInMonths: number;
}

/**
 * Интерфейс свойств, передаваемых в хук `usePromoState`.
 */
interface UsePromoStateProps {
  handleApplied: (code: string, result: AppliedData) => void;
  handleError: (code: string, error: string) => void;
  handleReset: () => void;
}

/**
 * Кастомный хук для управления состоянием промо-кода.
 *
 * @param {UsePromoStateProps} props - Объект с обработчиками событий.
 * @returns {{
 *   value: string;
 *   onChange: ChangeEventHandler<HTMLInputElement>;
 *   apply: () => Promise<void>;
 * }} Объект с текущим значением промо-кода, обработчиком изменения и функцией применения.
 */
export const usePromoState = ({ handleApplied, handleError, handleReset }: UsePromoStateProps) => {
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setValue(event.target.value?.toUpperCase());
  }, []);

  const apply = useCallback(async () => {
    setIsLoading(true);
    const { data, hasError, errors } = await network
      .request<AppliedData | { message: string }>('/stack-2/payment/validate-code', { baseUrl: process.env.PROTECTED_API_URL || '/api' })
      .query({ promotion_code: value.trim() })
      .get();
    setIsLoading(false);

    if (hasError || !data) {
      handleError(value.trim(), errors?.[0]?.message || 'Error apply code');
      return;
    }

    if ('message' in data) {
      handleError(value.trim(), data?.message);
      return;
    }

    if (!data?.valid) {
      handleError(value.trim(), 'The promo code is not valid');
      return;
    }

    const appliedData: AppliedData = {
      valid: data.valid,
      percentOff: data.percentOff,
      durationInMonths: data.durationInMonths,
    };

    handleApplied(value.trim(), appliedData);
  }, [value, handleApplied, handleError]);

  const reset = useCallback(() => {
    setValue('');
    handleReset?.();
  }, []);

  return {
    value,
    onChange,
    reset,
    apply,
    isLoading,
  };
};
