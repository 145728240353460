import React, { memo } from 'react';
import { useRoute } from 'navigation/hooks';
import { Typography } from '@mui/joy';

import useIsMy from '../model/useIsMy';
import useIsEditable from '../model/useIsEditable';
import useIsLoading from '../model/useIsLoading';

const TooLongStatus = () => {
  const route = useRoute<'Note'>();
  const id = route.params?.id === 'create' ? 'create' : Number(route.params?.id);
  const isMy = useIsMy(id);
  const isEditable = useIsEditable(id);
  const isLoading = useIsLoading(id);

  if (!(!isLoading && isMy && !isEditable)) {
    return null;
  }

  return (
    <Typography fontSize={12} mb={0.125} color="neutral">
      cant be edited
    </Typography>
  );
};

export default memo(TooLongStatus);
