import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import * as userProfile from 'store/nodes/user';

import * as selectors from '../store/selectors';
import type { DirectionType } from '../types';

type ButtonStateType = 'current' | 'incomplete' | 'cancelled' | 'available' | 'unavailable';

const useGetButtonState = () => {
  const user = useSelector(userProfile.selectors.getMy);
  const { id: currentPlanId, isPaused: currentIsPaused, isIncomplete: currentPlanIsIncomplete } = useSelector(selectors.currentPlan);

  return useCallback(
    (planId: string, directions: DirectionType[]): ButtonStateType => {
      if (!user) {
        return 'unavailable';
      }
      if (currentPlanId === planId && !currentIsPaused && !currentPlanIsIncomplete) {
        return 'current';
      }
      if (currentPlanId === planId && !currentIsPaused && currentPlanIsIncomplete) {
        return 'incomplete';
      }
      if (currentPlanId === planId && currentIsPaused) {
        return 'cancelled';
      }
      const allow = directions.find((item) => item.criterion.isLegal === (user.isLegal || false))?.allow;
      if (!allow) {
        return 'unavailable';
      }
      if (currentPlanId !== planId && !allow[currentPlanId || '']?.includes(planId)) {
        return 'unavailable';
      }
      return 'available';
    },
    [user, currentPlanId, currentIsPaused, currentPlanIsIncomplete],
  );
};

export default useGetButtonState;
