const insertCookiePolicyScripts = () => {
  const script = document.createElement('script');
  script.id = 'cookieyes';
  script.src = 'https://cdn-cookieyes.com/client_data/5b737b8e24b38c370d6c61db/script.js';
  document.head.appendChild(script);
  const style = document.createElement('style');
  style.innerText = `
    .cky-revisit-bottom-left {
      left: unset!important;
      right: 15px;
    }
  `;
  document.head.appendChild(style);
};

export default insertCookiePolicyScripts;
