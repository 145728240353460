import React, { memo, useState, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useRoute } from 'navigation/hooks';

import * as contentStore from 'store/nodes/content';
import { useDispatcher } from 'store/utils/redux/hooks';

import Copilot from 'widgets/Copilot';
import PanelsBar from 'widgets/PanelsBar';
import Notes from 'widgets/Notes';
import RelatedMaterials from 'widgets/RelatedMaterials';

import ContentPanel from '../panels/ContentPanel';

const LayoutDefault = () => {
  const route = useRoute<'Content'>();
  const { resourceId } = route.params;
  const dispatcher = useDispatcher();

  const [direction, setDirection] = useState<'row' | 'column'>('row');

  const data = useSelector(contentStore.selectors.dataById(resourceId));

  useEffect(() => {
    if (data?.id) {
      setTimeout(() => {
        dispatcher.copilot.open();
      }, 100);
    }
    return () => {
      // dispatcher.copilot.clear();
    };
  }, [data?.id]);

  const contentRender = useMemo(() => (data?.id ? <ContentPanel hasTitle={direction !== 'column'} resourceId={data.id} /> : null), [data?.id]);

  const panelsRender = useMemo(() => {
    return [
      ...(data?.id
        ? [
            {
              label: 'AI Copilot',
              value: 'copilot',
              component: <Copilot variant="material" resourceId={data.id} />,
            },
            {
              label: 'Remarks',
              value: 'notes',
              component: <Notes resourceType="material" resourceId={data.id} mixHighlight />,
            },
          ]
        : []),
      ...(data?.relatedIds
        ? [
            {
              label: 'Related',
              value: 'related',
              component: <RelatedMaterials resourceIds={data.relatedIds} />,
            },
          ]
        : []),
    ];
  }, [data?.id, data?.relatedIds]);

  if (!data) {
    return null;
  }

  return (
    <PanelsBar
      contentMeta={{
        label: 'Content',
        value: 'content',
      }}
      content={contentRender}
      panels={panelsRender}
      sx={{
        '@media (max-width: 767px)': {
          mx: -2,
        },
      }}
    />
  );
};

export default memo(LayoutDefault);
