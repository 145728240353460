import { Autocomplete, type AutocompleteProps } from '@mui/joy';
import { useState, useEffect, memo, useCallback } from 'react';
import { useFetchTags } from './model/useFetchTags';

interface TagsInputProps {
  value: string[];
  onChange?: (selectedOptions: string[]) => void;
  isDisabled?: boolean;
}

type ChangeHandler = NonNullable<AutocompleteProps<string, true, false, true>['onChange']>;

type InputChangeHandler = NonNullable<AutocompleteProps<string, true, false, true>['onInputChange']>;

const TagsInput = ({ value, onChange, isDisabled }: TagsInputProps) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchTags = useFetchTags();

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    setLoading(true);

    fetchTags(inputValue).then((results) => {
      if (active) {
        setOptions(results);
        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchTags]);

  const handleOnChange = useCallback<ChangeHandler>(
    (event, newValue) => {
      onChange?.(newValue.map((option) => option));
    },
    [onChange],
  );

  const handleInputChange = useCallback<InputChangeHandler>((event, newInputValue) => {
    setInputValue(newInputValue);
  }, []);

  return (
    <Autocomplete
      multiple
      freeSolo
      options={options}
      value={value}
      placeholder="Type to search"
      variant="plain"
      onChange={handleOnChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      loading={loading}
      disabled={isDisabled}
      slotProps={{
        listbox: {
          sx: {
            maxHeight: 190,
            overflow: 'auto',
          },
        },
      }}
    />
  );
};

export default memo(TagsInput);
